import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import "react-vertical-timeline-component/style.min.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import LoginForm from "../components/loginForm"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import CircularProgress from "@material-ui/core/CircularProgress"

const SchoolsPage = () => {
  const {
    admin,
    email,
    fetching,
    educatorsList,
    allStudentsList,
  } = useSelector(state => ({
    ...state.mainReducer.user.user,
    ...state.mainReducer,
  }))
  return (
    <>
      {email ? (
        <RenderList
          admin={admin}
          fetching={fetching}
          allStudentsList={allStudentsList}
          educatorsList={educatorsList}
        />
      ) : (
        <LoginForm />
      )}
    </>
  )
}

export default SchoolsPage

const RenderList = props => {
  const { educatorsList, allStudentsList, admin } = props
  if (props.educatorsList !== "" && props.fetching === false) {
    const educatorsList_no_admins = educatorsList.filter(
      educator => educator.admin === false
    )
    return (
      // <>
      <Layout>
        <CustomDiv>
          <table>
            <tbody>
              <CustomTr>
                <th>Educator Name</th>
                <th>Course Location</th>
                <th>Educator Email</th>
                <th>Date</th>
              </CustomTr>
              {educatorsList_no_admins.map((edu, index) => {
                const studentsList = allStudentsList.filter(
                  student => student.educator_email === edu.email
                )
                const filterStudents = admin
                  ? () => navigate("/studentsList", { state: { studentsList } })
                  : () => console.log("no admin", edu)
                return (
                  <StyledTr key={index} onClick={filterStudents}>
                    <td>{edu.name}</td>
                    <td>{edu.location}</td>
                    <td>{edu.email}</td>
                    <td>{edu.date}</td>
                  </StyledTr>
                )
              })}
            </tbody>
          </table>
        </CustomDiv>
      </Layout>
      // </>
    )
  }
  return (
    <Layout>
      <CustomDiv>
        <CircularProgress />
      </CustomDiv>
    </Layout>
  )
}

//styled-components
const CustomDiv = styled.div`
  margin: 10px 10px 40px 10px;
  // height: 100vh;
  // border: 1px solid grey;
  span {
    margin: 30px;
  }
  .MuiCircularProgress-root {
    margin: auto 50%;
    color: black;
  }
`
const CustomTr = styled.tr`
  background-color: black;
  color: white;
  padding: 10px;
  span {
    margin: 30px;
  }
`

const StyledTr = styled.tr`
  padding: 10px;
  :hover {
    background: #f0efefa3;
    cursor: pointer;
  }
  span {
    margin: 10px;
  }
`
